<template>
  <v-btn
    color="grey"
    outlined
    @click="onClick"
    :disabled="loading"
  >
    <v-progress-circular
      v-if="loading"
      :size="16"
      :width="2"
      class="mr-2"
      indeterminate
    ></v-progress-circular>
    Refresh
  </v-btn>
</template>

<script>
export default {
  props: ['loading', 'onClick']
}
</script>
